import styled from 'styled-components';

export const Container = styled.div`
  width: 75% !important;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding: 0 ${p => p.theme.spacings.lg};
  height: 5vh;
  width: 100%;
  background-color: ${p => p.theme.colors.gray};
  font-size: ${p => p.theme.fonts.size.md};
  font-weight: bold;

  button {
    margin-right: 0 !important;
    margin-left: ${p => p.theme.spacings.sm} !important;
  }

  .archived-text {
    margin-left: ${p => p.theme.spacings.md};
    color: ${p => p.theme.colors.primary};
    font-style: italic;
  }
`;

export const MessagesContainer = styled.div`
  padding: 10px;
  height: 74vh !important;
  background-color: ${p => p.theme.colors.grayDarkest};
  overflow-y: scroll;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${p => p.theme.colors.grayDarkest};
  }

  ::-webkit-scrollbar-corner {
    background-color: ${p => p.theme.colors.grayDarkest};
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`;

export const InputContainer = styled.div`
  width: 100% !important;
  height: 11vh !important;
  padding-top: ${p => p.theme.spacings.lg};
  margin: 0 !important;
  background-color: ${p => p.theme.colors.gray};

  button {
    margin: ${p => p.theme.spacings.md} !important;
  }

  .rdw-editor-toolbar {
    autofocus: true;
    background-color: ${p => p.theme.colors.gray};
    min-width: 6vh !important;
    max-width: 6vh !important;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;

    div {
      background-color: ${p => p.theme.colors.gray};
      border: none;
      margin: 0 !important;
      padding: 0 !important;
    }

    img {
      width: 120%;
    }
  }
  .DraftEditor-editorContainer {
    width: 100%;
    height: 5vh !important;
    background-color: ${p => p.theme.colors.white} !important;

    margin-left: ${p => p.theme.spacings.sm};
    margin-right: ${p => p.theme.spacings.sm};
    border-radius: 10px;
    overflow-y: scroll;
  }
  .rdw-emoji-modal {
    position: absolute;
    z-index: 1000 !important;
    box-sizing: content-box;

    transform: scale(1) translateY(-100%);
    transform-origin: 1em 0% 0px;
    top: 50%;
  }

  .wrapperClass {
    display: flex;
    flex-direction: row;
  }
  .editorClass {
    order: 2;
    height: 5vh !important;
    width: 100%;
    overflow-x: hidden;
    margin-right: ${p => p.theme.spacings.md};
  }
  .toolbarClass {
    display: flex;
    justify-content: center;
    order: 1;
    width: 1%;
    padding-left: ${p => p.theme.spacings.md} !important;

    .rdw-emoji-wrapper {
      padding: 0 !important;
    }
  }

  .file-attachments-container {
    display: flex;
    justify-content: flex-end;
    align-items: top;
    padding-right: ${p => p.theme.spacings.md};
    padding-top: ${p => p.theme.spacings.md};

    button {
      margin: 0 !important;
      margin-left: ${p => p.theme.spacings.md} !important;
      padding-left: ${p => p.theme.spacings.md} !important;
      padding-top: 0 !important;
    }
    button:focus {
      outline: 0;
    }
  }
`;

export const GetPreviousMessagesButtonContainer = styled.div`
  position: absolute;
  top: 10%;
  right: 20px;

  svg {
    margin: 0 !important;
  }
`;

export const ScrollToBottomButtonContainer = styled.div`
  position: absolute;
  bottom: 20%;
  right: 20px;

  * svg {
    margin: 0 !important;
  }
`;
